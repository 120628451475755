import React from "react"
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg"
import Awards from "../../components/Awards/Awards"
import LeftRightBox from "../../components/LeftRightBox/LeftRightBox"
import MainLayout from "../../layouts/MainLayout"
import { Link } from "gatsby"

import "./get-to-know-us.scss"

const OurStory = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("get-to-know-us:metaTitle")}>
      <>
        <HeroImg
          customClass="our-story-hero"
          subtitle={t("get-to-know-us:heroSubtitle")}
          title={t("get-to-know-us:heroTitle")}
          backgroundImage="bg/our-story-hero-new.jpg"
        />

        <div className="our-story">
          <div className="our-story-section-one">
            <p className="lato">{t("get-to-know-us:sectionOne")}</p>
          </div>

          <LeftRightBox>
            {<img src="/img/our-story-one-new.jpg" alt="our-story-one" />}
            {
              <div className="text-wrap">
                <h3 className="box">{t("get-to-know-us:sectionTwoTitle")}</h3>
                <p className="box">{t("get-to-know-us:sectionTwoText")}</p>
                <Link to={t("routes:about")}>
                  <button className="btn">{t("common:btnMore")}</button>
                </Link>
              </div>
            }
          </LeftRightBox>

          <LeftRightBox customClass="reverse">
            {
              <div className="text-wrap">
                <h3 className="box">{t("get-to-know-us:sectionThreeTitle")}</h3>
                <p className="box">{t("get-to-know-us:sectionThreeText")}</p>
                <Link to={t("routes:gorda")}>
                  <button className="btn">{t("common:btnMore")}</button>
                </Link>
              </div>
            }
            {<img src="/img/our-story-two.jpg" alt="our-story-two" />}
          </LeftRightBox>

          <div className="awards-section">
            <div className="position-awards">
              <h3 className="box">{t("get-to-know-us:awards")}</h3>
              <p>{t("get-to-know-us:awardsTextOne")}</p>
              <p className="box-three">{t("get-to-know-us:awardsTextTwo")}</p>
              <div className="awards">{t("get-to-know-us:awards")}</div>
              <Awards />
            </div>
          </div>

          <LeftRightBox>
            {<img src="/img/our-story-three.jpg" alt="our-story-three" />}
            {
              <div className="text-wrap">
                <h3 className="box">{t("get-to-know-us:sectionFourTitle")}</h3>
                <p className="box">
                {t("get-to-know-us:sectionFourText")}
                </p>
                <Link to={t("routes:production")}>
                  <button className="btn">{t("common:btnMore")}</button>
                </Link>
              </div>
            }
          </LeftRightBox>

          <LeftRightBox customClass="reverse">
            {
              <div className="text-wrap">
                <h3 className="box">{t("get-to-know-us:sectionFiveTitle")}</h3>
                <p className="box">
                {t("get-to-know-us:sectionFiveText")}
                </p>
                <Link to={t("routes:history")}>
                  <button className="btn">{t("common:btnMore")}</button>
                </Link>
              </div>
            }
            {<img src="/img/our-story-four.jpg" alt="our-story-four" />}
          </LeftRightBox>

          <div className="our-story-bottom-container">
            <HeroImg
              customClass="our-story-bottom"
              backgroundImage="bg/our-story-bottom.jpg"
            >
              {
                <div className="our-story-bottom-text">
                  <h3 className="box">{t("get-to-know-us:sectionSixTitle")}</h3>
                  <p className="box-two">
                  {t("get-to-know-us:sectionSixText")}
                  </p>
                  <p>
                  {t("get-to-know-us:sectionSixTextTwo")}
                  </p>
                </div>
              }
            </HeroImg>
          </div>
        </div>
      </>
    </MainLayout>
  )
}

export default OurStory
