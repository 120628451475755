import React from 'react';
import { useTranslation } from "react-i18next"
import Slider from "react-slick";
import "./awards.scss";

const Awards = () => {
  const { t } = useTranslation()

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="slider-position">
    <Slider {...settings}>
      <div>
        <img src={t("awards:awardsOne")} alt="awards-1" />
      </div>
      <div>
        <img src={t("awards:awardsTwo")} alt="awards-2" />
      </div>
      <div>
        <img src={t("awards:awardsThree")} alt="awards-3" />
      </div>
      <div>
        <img src={t("awards:awardsFour")} alt="awards-4" />
      </div>
      <div>
        <img src={t("awards:awardsFive")} alt="awards-5" />
      </div>
      <div>
        <img src={t("awards:awardsSix")} alt="awards-6" />
      </div>
      <div>
        <img src={t("awards:awardsSeven")} alt="awards-7" />
      </div>
      <div>
        <img src={t("awards:awardsEight")} alt="awards-8" />
      </div>
      <div>
        <img src={t("awards:awardsNine")} alt="awards-9" />
      </div>
      <div>
        <img src={t("awards:awardsTen")} alt="awards-10" />
      </div>
      <div>
        <img src={t("awards:awardsEleven")} alt="awards-11" />
      </div>
      <div>
        <img src={t("awards:awardsTwelve")} alt="awards-12" />
      </div>
      <div>
        <img src={t("awards:awardsThirteen")} alt="awards-13" />
      </div>
      <div>
        <img src={t("awards:awardsFourteen")} alt="awards-14" />
      </div>
      <div>
        <img src={t("awards:awardsFifteen")} alt="awards-15" />
      </div>
    </Slider>
    </div>
  )



}

export default Awards;